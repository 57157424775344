export default class User {
  constructor(
    customerID,
    portalID,
    userName,
    firstName,
    lastName,
    password,
    customerEmail,
    customerNumber,
    provider,
    isActive,
    createdDate,
    createdBy,
    lastActiveDate,
    forgetKey,
    forgetId
  ) {
    this.customerID = 0;
    this.portalID = 0;
    this.userName = '';
    this.firstName = '';
    this.lastName = '';
    this.password = '';
    this.customerEmail = '';
    this.customerNumber = '';
    this.provider = 1;
    this.isActive = true;
    this.createdDate = new Date().toISOString();
    this.createdBy = 0;
    this.lastActiveDate = new Date().toISOString();
    this.forgetKey = '';
    this.forgetId = 0;
  }
}
